<template>
  <div>
    <internet-plans-head
      class="mt-5 mb-5"
      @changeComponent="changeComponent"
    ></internet-plans-head>
    <internet-plans-table-head></internet-plans-table-head>
    <internet-plans-table-item
      v-for="item in items"
      :key="item.code"
      :item="item"
      @changeComponent="changeComponent"
      @edit="$emit('edit', item)"
      @delete="deleted"
    ></internet-plans-table-item>

    <modal-delete
      :dialogDelete="dialogDelete"
      :body="dialogDeleteBody"
      @cancel="dialogDelete = false"
    ></modal-delete>
  </div>
</template>

<script>
// Domains
import Plans from "../../domain/Plans";

export default {
  name: "InternetPlansTable",
  mounted() {
    this.getAllPlans();
  },
  components: {
    InternetPlansHead: () => import("./InternetPlansHead"),
    InternetPlansTableHead: () => import("./table/InternetPlansTableHead"),
    InternetPlansTableItem: () => import("./table/InternetPlansTableItem"),
    ModalDelete: () => import("../base/ModalDelete"),
  },
  data: () => ({
    dialogDelete: false,
    dialogDeleteBody: {
      title: "ELIMINAR PLAN DE INTERNET",
      type: "PLAN",
      question:
        "¿Esta seguro que quiere eliminar este plan de internet, una vez confirmada esta acción ya no se puede deshacer?",
    },
    items: [],
  }),
  methods: {
    changeComponent(component, item = {}) {
      this.$emit("changeComponent", component, item);
    },
    edit(item) {
      console.log(item);
    },
    async deleted({ code }) {
      try {
        await Plans.delete(code);
        await this.getAllPlans();
      } catch (error) {
        console.warn(error);
      }
    },
    async getAllPlans() {
      try {
        this.items = [];
        const response = await Plans.findByType('HOGAR');
        console.log(response);
        this.items = response;
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss">
.info--number,
.info--speed,
.info--price {
  width: 10%;
}

.info--name {
  width: 20%;
}

.info--description {
  width: 27%;
}

.info--status {
  width: 12%;
}

.info--actions {
  width: 11%;
}
</style>